/* src/styles/blogStyles.css */

article {
  font-family: Arial, sans-serif;
  line-height: 1.8;
  color: var(--chakra-colors-gray-800);
}

.chakra-ui-dark article {
  color: var(--chakra-colors-gray-200);
}

.blog-content h1 {
  color: var(--chakra-colors-primary-500);
  font-size: 2.5em;
  text-align: center;
  margin-bottom: 20px;
}

.blog-content h2 {
  color: var(--chakra-colors-secondary-500);
  font-size: 2em;
  margin-top: 30px;
  margin-bottom: 10px;
}

.links {
  color: var(--chakra-colors-secondary-500);
}

.blog-content p {
  margin-bottom: 20px;
}

.blog-content ul {
  list-style-type: disc;
  padding-left: 20px;
  margin-bottom: 20px;
}

.blog-content li {
  margin-bottom: 10px;
}

strong {
  font-weight: bold;
}

a {
  color: var(--chakra-colors-primary-500);
  text-decoration: none;
  font-weight: bold;
}

a:hover {
  text-decoration: underline;
}

.text-center {
  text-align: center;
}

.margin-top {
  margin-top: 40px;
}

@media (max-width: 500px) {
  .blog-content h2 {
    font-size: 1.5rem; /* Adjust this value as needed */
  }
}

.slick-slide {
  padding: 0 8px;
  box-sizing: border-box;
}
